$theme-colors: (
  "primary": #3AAFD3,
  "primary-darker": #4464D4,
  "warning": #EBB728,
  "success": #2FD4A1,
  "danger": #D46B4E,
);

@import url("https://fonts.googleapis.com/css?family=Arvo|Lato");
@import "~bootstrap/scss/bootstrap";

html, body, #root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'Lato', Sans-Serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Arvo', Serif;
}

.navbar {
  background-color: #FFF;
}

.nav-link.active:after {
  content: '';
  left: 0;
  right: 0;
  bottom: calc(100% + 0.5rem - 2px);
  position: absolute;
  border-bottom: 4px solid theme-color("primary");
}

.navbar-secondary {
  border-top: 1px solid $gray-200;
  border-bottom: 2px solid $gray-300;

  .nav-link.active:after {
    top: calc(100% + 0.25rem);
  }
}

.nav-link {
  position: relative;
}

main {
  width: 100%;
  height: 100%;
  background-color: $gray-200;
}

iframe {
  border: none;
  height: 100%;
  width: 100%;
  margin-bottom: -50px;
}

.navbar-brand {
  font-family: 'Arvo', Serif;
  font-size: 1.45rem;
  background-color: theme-color("primary");
  background-image: linear-gradient(180deg, theme-color("primary"), theme-color("primary-darker"));
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  // font-Size: 44px;
}

.board {
  margin-bottom: -48px;

  &__wrapper {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.08);
  }
}

.rectangle {
  padding: 0.5rem;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-evenly;
  position: relative;
  flex-direction: column;
}

.players {
  @extend .rectangle;

  display: flex;
  flex-direction: row;
  
  @media(min-width: 720px) {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__wrapper {
    padding: 0 1rem 1rem 1rem;

    @media(min-width: 720px) {
      padding: 0.5rem;
      z-index: 1000;
      position: fixed;
      display: block;
      top: 4.25rem;
      bottom: 0;
      left: 0.10rem;
      right: auto;
      width: 100px;
    }
  }

}

.player {
  text-align: center;
  
  @media(min-width: 720px) {
    margin-bottom: 0.5rem;
  }

  svg circle.player__health {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  &__name {
    display: block;
    font-size: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dice-wrapper {
  z-index: 1000;
  padding: 0.5rem;
  position: fixed;
  top: 4.25rem;
  bottom: 0;
  left: 0.10rem;
  right: 0.10rem;

  @media(min-width: 720px) {
    display: block;
    right: auto;
    width: 90px;
  }

  .dice-rectangle {
    padding: 0.5rem;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.08);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.dice {
  margin-bottom: 0.5rem;
  position: relative;

  &__icon {
    transition: transform 0.5s;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__results {
    height: 80%;
    width: 50px;
    position: absolute;
    top: 10%;
    left: calc(100% + 0.5rem);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 .5rem 0 0 ;
    color: #838383;
    box-shadow: none;
    border-left: none;
    text-align: center;
    font-weight: bold;
    // background: linear-gradient(45deg, #133657, #456079);
  }
}

.dice-results {
  z-index: 1000;
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  width: 18rem;
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding-bottom: 1.5rem;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  // @include media-breakpoint-up(xl) {
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  // }
}

.card-campaign {
  border: none;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;

  border-left: 8px solid theme-color("warning");
  transition: transform 0.5s;

  &:hover {
    cursor: pointer;
    transform: scale(1.025);
  }
}

.scrollable {
  overflow-y: auto;
}